.modal-endgame {
    &.win {
        color: #006600;
    }

    &.lose {
        color: #660000;
    }

    p {
        font-size: 1.5em;    
        font-weight: bolder;
        text-align: center;
    }

    .row {
        text-align: center;
        display: block;
        margin-top: 15px;
        
        button {
            cursor: pointer;
            font-size: 1.7em;
            line-height: 50px;
            width: 50px;
            height: 50px;
        }

        .record {
            font-size: 1.7em;
            border: 1px dashed black;
            padding: 10px;

            .trophies {
                .trophy {
                    display: inline;
                }
            }

            .seconds {
                display: block;
                margin: 5px;
            }
        }
    }
}

section.campo-minado {
    display: flex;

    div.game {
        display: flex;
        justify-content: center;
        flex-direction: column;
        min-width: 210px;

        .config {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background-color: rgb(176, 181, 185);
            border-bottom: 1px solid #000000;
            padding: 8px 0 8px 0;
            font-size: 0.9rem;
            
            div.flag {
                display: inline;
                margin-right: 15px;
                margin-left: 10px;
            }

            div.elapsedSeconds {
                margin-right: 10px;
            }
        }

        .main {
            background-color: rgb(166, 186, 206);
            padding: 3px 0 3px 0;

            .square-row {
                display: flex;
                justify-content: center;
                margin: 0;
                
                .square {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 0;
                    margin: 0;
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: 0.95rem;
                    width: 21px;
                    height: 21px;
                    text-align: center;
                    cursor: pointer;
                    user-select: none; /* supported by Chrome and Opera */
                    -webkit-user-select: none; /* Safari */
                    -khtml-user-select: none; /* Konqueror HTML */
                    -moz-user-select: none; /* Firefox */
                    -ms-user-select: none; /* Internet Explorer/Edge */
                    transition: background-color .2s linear;

                    &.easy {
                        width: 45px;
                        height: 45px;
                    }

                    &.medium {
                        width: 27px;
                        height: 27px;
                    }
        
                    &.even {
                        &.not-showing {
                            background-color: #009900;

                            &:hover {
                                background-color: #3399FF;
                            }
                        }
        
                        &.showing {
                            background-color: rgb(184, 212, 240);
                        }
                    }
        
                    &.odd {
                        &.not-showing {
                            background-color: #006600;

                            &:hover {
                                background-color: #3399FF;
                            }
                        }
        
                        &.showing {
                            background-color: rgb(131, 151, 172);
                        }
                    }
                }
            }
        }
    }
    
}
